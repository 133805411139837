.weather-card {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #333;
    color: #fff;
    border-radius: 10px;
    z-index: 1;
}

.left-side, .right-side {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
}

.left-side {
    align-items: flex-start;
    margin-top: -40px;
    padding-right: 15px;
}

.right-side {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.left-side p {
    margin: 0;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}

.weather-faded {
    opacity: 0.8;
}

.temperature {
    font-size: 45px;
    font-weight: bold;
    /* color: #FF5E5B; */
    margin-bottom: 5px;
}

.humidity, .uvi, .condition {
    font-size: 22px;
    opacity: 1;
}

.red {
    color: #FF5E5B;
}

.pink {
    color: #FFD2FF;
}

.orange {
    color: #FF9A5B;
}

.yellow {
    color: #ffe45b;
}

.hide-hover {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.weather-card:hover .hide-hover {
    opacity: 1;
}

.hour-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #444;
    border-radius: 8px;
    position: relative;
    padding: 0px 15px;
    margin: 0;
    z-index: 1;
    box-shadow: rgba(0, 0, 3, 0.1) 0px 30px 50px -12px inset, rgba(0, 0, 0, 0.1) 0px 18px 26px -18px inset, rgba(0, 0, 0, 0.2) 0px -2px 6px 0px inset;
}

.hour {
    position: absolute;
    top: -10px;
    left: 5px;
    font-size: 14px;
    font-weight: bold;
    opacity: 0.6;
    padding-bottom: 0;
    margin-bottom: 0;
}

.hour-temp {
    font-size: 24px;
    font-weight: bold;
    /* color: #FF5E5Bcf; */
    margin-bottom: 0;
    margin-top: 10px;
}
