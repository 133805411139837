#checkbox {
    display: none;
}
  
.toggle {
    position: relative;
    width: 40px;
    cursor: pointer;
    margin: auto;
    display: block;
    height: calc(4px * 3 + 11px * 2);
}

.toggle:hover {
    filter: brightness(1.3);
}
  
.bar {
    position: absolute;
    left: 0;
    right: 0;
    height: 4px;
    border-radius: calc(4px / 2);
    background: #ddd;
    color: inherit;
    opacity: 1;
    transition: none 0.35s cubic-bezier(.5,-0.35,.35,1.5) 0s;
}

.bar:hover {
    filter: brightness(1.1);
}
  
.bar--top {
    bottom: calc(50% + 8px + 4px/ 2);
    transition-property: bottom,transform;
    transition-delay: calc(0s + 0.35s),0s;
}

.bar--top:hover {
    filter: brightness(1.3);
}
  
.bar--middle {
    top: calc(50% - 4px/ 2);
    transition-property: opacity;
    transition-delay: calc(0s + 0.35s);
}

.bar--middle:hover {
    filter: brightness(1.3);
}
  
.bar--bottom {
    top: calc(50% + 8px + 4px/ 2);
    transition-property: top,transform;
    transition-delay: calc(0s + 0.35s),0s;
}

.bar--bottom:hover {
    filter: brightness(1.3);
}
  
#checkbox:checked + .toggle .bar--top {
    bottom: calc(50% - 4px/ 2);
    transition-delay: 0s,calc(0s + 0.35s);
}
  
#checkbox:checked + .toggle .bar--middle {
    opacity: 0;
    transition-duration: 0s;
    transition-delay: calc(0s + 0.35s);
}
  
#checkbox:checked + .toggle .bar--bottom {
    top: calc(50% - 4px/ 2);
    transition-delay: 0s,calc(0s + 0.35s);
}

@media (max-width: 768px) {
    .toggle {
        width: 35px;
        height: calc(4px * 3 + 10px * 2);
    }

    .bar {
        height: 4px;
    }

    .bar--top {
        bottom: calc(50% + 5px + 4px/ 2);
        transition-property: bottom,transform;
        transition-delay: calc(0s + 0.35s),0s;
    }

    .bar--bottom {
        top: calc(50% + 5px + 4px/ 2);
        transition-property: top,transform;
        transition-delay: calc(0s + 0.35s),0s;
    }
}