.source-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
    /* width: 100%; */
}

.source-modal__header-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    font-size: 1.3em;
    font-weight: 600;
    padding: 0.1rem 1.4rem;
    border-radius: 10px;
    height: 3.25rem;
    border: none;
    background-color: #FF5E5B;
    box-shadow: 0px 1px 0px 0px #ffffff90;
    color: #222;
}

.source-modal__header-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0px 2px 0px 0px #ffffff90;
    filter: brightness(1.1);
}

.source-modal__title {
    font-size: 1.4rem;
    font-weight: 700;
}

.source-modal .feather {
    stroke-width: 2;
    width: 2.2rem;
    height: 2.2rem;
    color: #eee;
    cursor: pointer;
    animation: rotate 0.5s ease-in-out;
}

.source-modal .feather .up {
    animation: rotate 0.5s ease-in-out;
    transform: rotate(180deg);
}

.source-modal .feather .down {
    animation: rotate 0.5s ease-in-out;
    transform: rotate(0deg);
}

.source-modal__body {
    position: absolute;
    top: 4rem;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: #333;
    padding: 1rem;
    border-radius: 1.5rem;
}

.source-category {
    margin-top: 0rem;
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: auto;
    margin-bottom: .5rem;
}

.source-category::-webkit-scrollbar {
    height: .3rem;
    width: .1rem;
    background-color: #eee;
    border-radius: 5rem;
}

.source-category::-webkit-scrollbar-thumb {
    background-color: #FF5E5B;
    border-radius: 5rem;
}

.source-category__buttons {
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    -ms-overflow-style: none;
    padding-bottom: .3rem;
    overflow-x: auto;
}

.source-modal__source-button {
    font-size: 1.1rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
    color: #222;
    background-color: #eee;
    border: none;
    white-space: nowrap;
    border-radius: .5rem;
    padding: 0.5rem 1rem;
    margin: 0.3rem;
    opacity: 0.6;
}

.source-modal__header-btn.hidden {
    display: none;
}  

.source-modal__source-button:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
}

.source-modal__source-button--selected {
    background-color: #FF5E5B;
    color: #222;
    font-size: 1.3rem;
    font-weight: 500;
    border: none;
    white-space: nowrap;
    border-radius: .5rem;
    padding: 0.5rem 1rem;
    margin: 0.3rem;
    opacity: 1;
}

.source-modal__source-button--selected:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
}

.source-modal__top-button {
    background-color: #eeeeee;
    color: #222;
    font-size: 1.1rem;
    font-weight: 500;
    border: none;
    border-radius: .5rem;
    padding: 0.5rem 1rem;
    margin: 0.3rem;
    opacity: 1;
}

.source-modal__top-button:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.05);
}

.source-modal-close-button {
    position: absolute;
    right: 5px;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .9rem 1rem;
    background-color: transparent;
    border: none;
}

.source-modal-close-button.filled {
    background-color: #FF5E5B;
    border-radius: .4rem;
    padding: 1rem 1rem;
    top: .9rem;
    right: .4rem;
}

.close-button .feater-x {
    position: absolute;
    right: 5px;
    top: 5px;

    width: 1.5rem;
    height: 1.5rem;
    color: #222;
}

.source-modal-close-button .feather-x {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 1.5rem;
    height: 1.5rem;
    stroke-width: 2;
    color: #ddd;
}

@media (max-width: 768px) {
    .source-modal {
        width: 100%;
        margin-top: -1rem;
    }
    
    .source-modal__body {
        width: 90%;
        /* left: 0.5rem; */
        top: 1rem;
    }

    .source-modal__header {
        gap: 0.2rem;
        width: 100%;
        
    }

    .source-modal__title {
        font-size: 1.3rem;
    }

    .source-modal .feather {
        width: 2rem;
        height: 2rem;
    }

    .source-modal__top-button {
        font-size: 1.1rem;
    }

    .source-modal__source-button {
        font-size: 1.1rem;
        margin: 0.2rem;
    }

    .source-modal__source-button--selected {
        font-size: 1.1rem;
        margin: 0.2rem;
    }

    .source-modal__header-btn {
        height: 2.6rem;
        width: 25%;
        margin-top: 0.5rem;
    }
}